<template>
  <q-card class="q-px-md q-py-md">
    <div class="col">
      <div class="row q-col-gutter-md">
        <template v-if="!loading">
          <div
            v-for="(type, index) in contentTypes"
            :key="index"
            class="col-3 cursor-pointer type-btn"
            @click="!hasRole('guest') ? toggle(type) : null"
          >
            <m-travel-icon :type="type" color="white" :bg-color="!isOrgDisabled(type) ? !isUserDisabled(type) ? getTravelContentHex(type) : '#757575' : '#757575'" circle />
            <div class="text-subtitle1">
              {{ $tc(`content_type.${type}`) }}
            </div>
          </div>
        </template>
        <template v-else>
          <div v-for="i in 4" :key="i" class="col-3 row justify-center">
            <q-skeleton type="circle" size="64px" class="col-12" />
            <div class="col-12 row justify-center">
              <q-skeleton
                height="10px"
                width="50%"
                class="q-mt-sm"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </q-card>
</template>
<script>
import authentication from 'mixins/authentication'
import travelContents from 'mixins/travelContents'
import { mapGetters } from 'vuex'
import { MTravelIcon } from 'components'

export default {
  components: {
    // Doesn't like the traditional import for some reason
    MTravelIcon
  },
  mixins: [authentication, travelContents],
  props: {
    loading: {
      type: Boolean
    },
    contentTypes: {
      type: Array,
      required: true
    },
    userTypes: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: Array
    },
    organisation: {
      type: Object
    },
    orgTypes: {
      type: Array,
      default: () => ([])
    },
    user: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner',
      travelPreferences: 'preferences'
    }),
    enabledContentTypes: {
      get () {
        return this.val
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  methods: {
    toggle (type) {
      const pref = this.getTravelPreferenceName(type)
      if (!this.user) {
        if (!this.isOrgDisabled(pref)) {
          this.$emit('toggle', pref, true)
          if (this.travelPreferences[pref]) this.travelPreferences[pref].hidden = true
        } else {
          this.$emit('toggle', pref, false)
          if (this.travelPreferences[pref]) this.travelPreferences[pref].hidden = false
        }
      } else {
        if (!this.isUserDisabled(pref)) {
          this.$emit('toggle', pref, true)
          if (this.travelPreferences[pref]) this.travelPreferences[pref].hidden = true
        } else {
          this.$emit('toggle', pref, false)
          if (this.travelPreferences[pref]) this.travelPreferences[pref].hidden = false
        }
      }
    },
    isOrgDisabled (type) {
      const pref = this.getTravelPreferenceName(type)
      return !this.orgTypes.includes(pref)
    },
    isUserDisabled (type) {
      return this.userTypes.includes(type)
    }
  }
}
</script>
<style lang="stylus">

.type-btn
  display flex
  flex-direction column
  align-items center
  text-align center
</style>
